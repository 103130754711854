import React, { Component } from "react";
import { useEffect } from "react";
import AllGames from '../../components/AllGames';

//state 
const Explore = () => {
        useEffect(() => {
            document.body.style.backgroundColor = "#13c2ec";  // Set background color
    
            // Cleanup function to reset the background when the component unmounts
            return () => {
                document.body.style.backgroundColor = "";  // Reset to default
            };
        }, []);
        
    
        return (
            <div className="background-blue-95vh">
                <div className="main-content">
                <AllGames/>

                </div>
            </div>

        )
    
}

export default Explore;