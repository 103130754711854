import { Link } from "react-router-dom";

const NotFound = () => {
    return (
        <div 
            style={{
                backgroundColor: "#13c2ec",
                color: "white",
                height: "95vh",  // Full height of the screen
                display: "flex",  // Flexbox to center content
                justifyContent: "center",  // Horizontally center content
                alignItems: "center",  // Vertically center content
                flexDirection: "column",  // Stack text vertically
                textAlign: "center", 
                padding: "20px"
            }}
            className="titan-one-regular"
        >
            <h1 style={{ fontSize: "3rem" }}>404 - Page Not Found</h1>
            <p style={{ fontSize: "1.5rem" }}>Oops! The page you are looking for doesn't exist.</p>
            <Link 
                to="/" 
                style={{ 
                    color: "white", 
                    fontSize: "1.2rem", 
                    textDecoration: "underline", 
                    marginTop: "20px"
                }}
            >
                Return to Home
            </Link>
        </div>
    );
};

export default NotFound;
