import React, { Component } from "react";
import GameCard from "../../components/GameCard";
import "./style.css"
import API from "../../utils/API";
import { useState, useContext,useCallback, useEffect } from "react";
import { Input, Container, Row, Col, Card, CardImg, CardText, CardBody,
    CardTitle, Button, 
    CardFooter} from 'reactstrap';
    import { BrowserRouter as Router, Route, Routes, NavLink, Link} from "react-router-dom";
import './style.css';
import CreateSet from '../../components/CreateSet';
import MyGames from '../../components/MyGames';
import AllGames from '../../components/AllGames';
import Class from '../../components/Class';
import Setting from '../../components/Setting';
import EditKooblo from "../../components/EditKooblo";
import EditKoobloParty from "../../components/EditKoobloParty";
import SubscriptionManager from "../../components/Subscription";
import SubscriptionSuccess from "../../components/Subscription/SubscriptionSuccess";
import GameHistory from "../../components/GameHistory/GameHistory";
//state 
const Dashboard = () => {
           // Set background color when the component mounts, reset it when it unmounts
    useEffect(() => {
        document.body.style.backgroundColor = "#13c2ec";  // Set background color

        // Cleanup function to reset the background when the component unmounts
        return () => {
            document.body.style.backgroundColor = "";  // Reset to default
        };
    }, []);
    
        return (
            <div className="background-blue-95vh">

                <div className="container-fluid">
                <div className="dropdown d-block d-sm-none">
                            <button className="btn btn-light-purple dropdown-toggle nunito" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-expanded="false">
                                Menu
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <li><NavLink to="/dashboard/create" className="dropdown-item">Create</NavLink></li>
                                <li><NavLink to="/dashboard/mygames" className="dropdown-item">My Games</NavLink></li>
                                <li><NavLink to="/dashboard/explore" className="dropdown-item">Explore</NavLink></li>
                                <li><Link to="/dashboard/history" className="dropdown-item">History</Link></li>
                                <li><NavLink to="/dashboard/settings" className="dropdown-item">Settings</NavLink></li>
                            </ul>
                        </div>

                    <div className="row">

                        <div className="col-auto sidebar ">
                                    <div className="d-flex flex-column align-items-lg-start pt-2 text-white">
                                        
                                            <Link to="/dashboard" className="nav-link  sidebar-header">
                                                <h3>K<span className=" d-none d-lg-inline">ooblo</span></h3>
                                            </Link>


                                        <ul className="nav  flex-column  align-items-lg-start w-100" id="menu">
                                            <li className="nav-item w-100 sidebar-text">
                                                <NavLink to="/dashboard/create" className="nav-link">
                                                    <i className="fa-solid fa-pen-to-square"></i> <span className="ms-1 d-none d-lg-inline">Create</span>
                                                </NavLink>
                                            </li>

                                            <li className="nav-item w-100 sidebar-text">

                                            <NavLink to="/dashboard/mygames" className="nav-link" >
                                                <i className="fa-regular fa-folder"></i> <span className="ms-1 d-none d-lg-inline">My Games</span>
                                            </NavLink>
                                            </li>
                                            <li className="nav-item w-100 sidebar-text">
                                                <NavLink to="/dashboard/explore"  className="nav-link ">
                                                <i className="fa-solid fa-magnifying-glass"></i> <span className="ms-1 d-none d-lg-inline">Explore</span>
                                                </NavLink>
                                            </li>
                                            <li class="nav-item w-100 sidebar-text">
                                                <Link to="/dashboard/history" class="nav-link ">
                                                <i class="fa-solid fa-clock-rotate-left small-icon"></i> <span class="ms-1 d-none d-lg-inline">History</span>
                                                </Link>
                                            </li>
                                            <li className="nav-item w-100 sidebar-text">
                                                <NavLink to="/dashboard/settings" className="nav-link ">
                                                <i className="fa-solid fa-gear"></i> <span className="ms-1 d-none d-lg-inline">Settings</span>
                                                </NavLink>
                                            </li>
                                        </ul>
                                        <hr/>
                                    </div>
                    

                        </div>
                        
                        <div className="col py-3 main-content ">
                        <Routes>
                            <Route path=""element={<MyGames/>} />
                            <Route path="create" element={<CreateSet/>} />
                            <Route path="MyGames" element={<MyGames/>} />
                            <Route path="explore" element={<AllGames/>} />
                            {/* <Route path="Classes" element={<Class/>} /> */}
                            <Route path="history" element={<GameHistory/>} />
                            <Route path="Settings" element={<Setting/>} />
                            <Route path="kooblo/edit/:id" element={<EditKooblo/>} />
                            <Route path="koobloparty/edit/:id" element={<EditKoobloParty/>} />
                            <Route path="subscription" element={<SubscriptionManager/>} />
                            <Route path="subscription/success" element={<SubscriptionSuccess/>} />
                        </Routes>

                            {/* {renderComponent()} */}

                        </div>
                    </div>
                </div>


            </div>
        )
    
}

export default Dashboard;