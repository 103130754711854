import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Button } from "reactstrap";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import UserQuizReport from './UserQuizReport';
const HostQuizReport = ({ quizData,isSubscribed }) => {
  const [selectedUser, setSelectedUser] = useState(null); // State to track the selected user for detailed view
  const navigate = useNavigate(); // React Router navigation hook

    // quizData = [
    //   {
    //     username: "lijack2",
    //     avatar:"https://api.multiavatar.com/lijack2.svg",
    //     score: 326,
    //     quizData: {
    //         questions: [
    //             {
    //             id: 58,
    //             question: "What is 2+2?",
    //             selectedAnswer: { answer: "4", correctAnswer: "true" },
    //             multiAnswers: {
    //                 answer1: { answer: "4", correctAnswer: "true" },
    //                 answer2: { answer: "6", correctAnswer: "false" },
    //                 answer3: { answer: "3", correctAnswer: "false" },
    //                 answer4: { answer: "2", correctAnswer: "false" },
    //             },
    //             image:
    //                 "https://images.unsplash.com/photo-1536146021566-627ce3c4d813?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w2NTIzNjl8MHwxfHNlYXJjaHw5fHxtb25rZXl8ZW58MHx8fHwxNzI1OTg2ODg2fDA&ixlib=rb-4.0.3&q=80&w=400",
    //             },
    //             {
    //             id: 41,
    //             question: "What is 5+5?",
    //             selectedAnswer: { answer: "10", correctAnswer: "true" },
    //             multiAnswers: {
    //                 answer1: { answer: "10", correctAnswer: "true" },
    //                 answer2: { answer: "9", correctAnswer: "false" },
    //                 answer3: { answer: "8", correctAnswer: "false" },
    //                 answer4: { answer: "7", correctAnswer: "false" },
    //             },
    //             image: "https://edu-game.s3.amazonaws.com/1721498665146637.jpg",
    //             },
    //             {
    //             id: 38,
    //             question: "What is 1+1?",
    //             selectedAnswer: { answer: "3", correctAnswer: "false" },
    //             multiAnswers: {
    //                 answer1: { answer: "2", correctAnswer: "true" },
    //                 answer2: { answer: "3", correctAnswer: "false" },
    //                 answer3: { answer: "4", correctAnswer: "false" },
    //                 answer4: { answer: "5", correctAnswer: "false" },
    //             },
    //             image:
    //                 "https://cdn.pixabay.com/photo/2017/01/12/21/19/search-1975707_150.jpg",
    //             },
    //             {
    //             id: 41,
    //             question: "What is 5+5?",
    //             selectedAnswer: { answer: "10", correctAnswer: "true" },
    //             multiAnswers: {
    //                 answer1: { answer: "10", correctAnswer: "true" },
    //                 answer2: { answer: "9", correctAnswer: "false" },
    //                 answer3: { answer: "8", correctAnswer: "false" },
    //                 answer4: { answer: "7", correctAnswer: "false" },
    //             },
    //             image: "https://edu-game.s3.amazonaws.com/1721498665146637.jpg",
    //             },
    //         ],
    //         questionsAnswered: 4,
    //         totalCorrect: 2,
    //     },
    //   },
    //   {
    //     username: "Peter",
    //     avatar:"https://api.multiavatar.com/Peter.svg",
    //     score: 476,
    //     quizData: {
    //         questions: [
    //             {
    //             id: 58,
    //             question: "What is 2+2?",
    //             selectedAnswer: { answer: "4", correctAnswer: "true" },
    //             multiAnswers: {
    //                 answer1: { answer: "4", correctAnswer: "true" },
    //                 answer2: { answer: "6", correctAnswer: "false" },
    //                 answer3: { answer: "3", correctAnswer: "false" },
    //                 answer4: { answer: "2", correctAnswer: "false" },
    //             },
    //             image:
    //                 "https://images.unsplash.com/photo-1536146021566-627ce3c4d813?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w2NTIzNjl8MHwxfHNlYXJjaHw5fHxtb25rZXl8ZW58MHx8fHwxNzI1OTg2ODg2fDA&ixlib=rb-4.0.3&q=80&w=400",
    //             },
    //             {
    //             id: 41,
    //             question: "What is 5+5?",
    //             selectedAnswer: { answer: "10", correctAnswer: "true" },
    //             multiAnswers: {
    //                 answer1: { answer: "10", correctAnswer: "true" },
    //                 answer2: { answer: "9", correctAnswer: "false" },
    //                 answer3: { answer: "8", correctAnswer: "false" },
    //                 answer4: { answer: "7", correctAnswer: "false" },
    //             },
    //             image: "https://edu-game.s3.amazonaws.com/1721498665146637.jpg",
    //             },
    //             {
    //             id: 38,
    //             question: "What is 1+1?",
    //             selectedAnswer: { answer: "2", correctAnswer: "true" },
    //             multiAnswers: {
    //                 answer1: { answer: "2", correctAnswer: "true" },
    //                 answer2: { answer: "3", correctAnswer: "false" },
    //                 answer3: { answer: "4", correctAnswer: "false" },
    //                 answer4: { answer: "5", correctAnswer: "false" },
    //             },
    //             image:
    //                 "https://cdn.pixabay.com/photo/2017/01/12/21/19/search-1975707_150.jpg",
    //             },
    //             {
    //             id: 41,
    //             question: "What is 5+5?",
    //             selectedAnswer: { answer: "9", correctAnswer: "false" },
    //             multiAnswers: {
    //                 answer1: { answer: "10", correctAnswer: "true" },
    //                 answer2: { answer: "9", correctAnswer: "false" },
    //                 answer3: { answer: "8", correctAnswer: "false" },
    //                 answer4: { answer: "7", correctAnswer: "false" },
    //             },
    //             image: "https://edu-game.s3.amazonaws.com/1721498665146637.jpg",
    //             },
    //         ],
    //         questionsAnswered: 4,
    //         totalCorrect: 3,
    //     },
    //   },
    // ];
  
    if (!quizData || !Array.isArray(quizData)) {
      return null;
    }

    // Calculate overall percentage
    const totalCorrect = quizData.reduce(
      (sum, entry) => sum + entry.quizData.totalCorrect,
      0
    );
    const totalQuestionsAnswered = quizData.reduce(
      (sum, entry) => sum + entry.quizData.questionsAnswered,
      0
    );
    const percentageCorrect = Math.round((totalCorrect / totalQuestionsAnswered) * 100);

    // Prepare data for the pie chart
    const pieData = [
      { name: "Correct", value: totalCorrect },
      { name: "Incorrect", value: totalQuestionsAnswered - totalCorrect },
    ];

    // Pie chart colors
    const pieColors = ["#1ff31e", "#fb3c3c"];

    const percentageColor = percentageCorrect >= 70 ? "#1ff31e" : percentageCorrect >= 50 ? "#ffa500" : "#fb3c3c";

    // Handle selecting a user
    const handleUserSelect = (user) => {
      setSelectedUser(user);
    };
  // Handle "See Overall Data" button
  const handleSeeOverallData = () => {
    const overallData = {
      username: "Overall",
      avatar: null,
      score: null, // Optional, leave null or calculate if needed
      quizData: {
        questions: quizData.flatMap((user) => user.quizData.questions),
        questionsAnswered: totalQuestionsAnswered,
        totalCorrect: totalCorrect,
      },
    };
    setSelectedUser(overallData);
  };
      return (
        <Container className="mt-4 mb-4">
          <Row>
            <Col>
              <Card className="p-4">
                {!selectedUser ? (<>
                  <div
                  onClick={isSubscribed ? () => handleSeeOverallData() : null}
                  style={{
                    cursor: isSubscribed ? "pointer" : null,
                    position: "relative",
                  }}
                >
                  <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: 1 }} />
                  
                  {/* Actual Content */}
                  <h5 className="text-center">Overall Performance</h5>
                  <h6 className="text-center">
                    Correct Answers: {totalCorrect}/{totalQuestionsAnswered} ({percentageCorrect}%)
                  </h6>
                  <div className="d-flex justify-content-center position-relative mb-4">
                      <div
                          className="position-absolute"
                          style={{
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            fontSize: "1.5rem",
                            fontWeight: "bold",
                            color: percentageColor,
                          }}
                      >
                          {percentageCorrect}%
                      </div>
                      <PieChart width={300} height={300}>
                          <Pie
                              data={pieData}
                              cx="50%"
                              cy="50%"
                              innerRadius={70}
                              outerRadius={100}
                              fill="#8884d8"
                              dataKey="value"
                              startAngle={90}
                              endAngle={450}
                              paddingAngle={1}
                          >
                              {pieData.map((entry, index) => (
                                  <Cell key={`cell-${index}`} fill={pieColors[index]} />
                              ))}
                          </Pie>
                          <Tooltip />
                      </PieChart>
                  </div>
                  <p className="text-center">
                    {isSubscribed ? 'Click for more details' : 'Subscribe for more details'}
                  </p>
              </div>
                <hr></hr>
                <h5 className="text-center m-4">User Breakdown</h5>


                <div style={{ position: "relative", minHeight:isSubscribed ?null:"300px", borderRadius: "10px" }}> {/* Added borderRadius and overflow */}
                <div
                    style={{
                      display: isSubscribed ? "none" : "block",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      background: "rgba(0, 0, 0, 0.5)",
                      backdropFilter: "blur(5px)",
                      zIndex: 2,
                    }}
                  />
                  <div
                    style={{
                      display: isSubscribed ? "none" : "flex",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      zIndex: 3,
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "20px",
                      background: "white",
                      borderRadius: "10px",
                      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    <h5>Unlock Full Access</h5>
                    <p>Get Kooblo Plus to see detailed user data!</p>
                    <Button color="primary" onClick={() => navigate("/dashboard/subscription")}>
                    Get Kooblo Plus Now
                    </Button>
                  </div>

                  {quizData.map((user) => (
                    <Card
                      key={user.username}
                      className="mb-4"
                      onClick={() => handleUserSelect(user)}
                      style={{ cursor: "pointer", opacity: isSubscribed ? 1 : 0.4 }}
                    >
                      <Row className="p-3 align-items-center">
                      <Col sm={4} className="d-flex align-items-center justify-content-center">
                        <img
                          src={user.avatar}
                          alt={`${user.username} avatar`}
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "50%",
                            objectFit: "cover",
                            marginRight: "10px", // Add spacing between avatar and username
                          }}
                        />
                        <h6 className="mb-0">{user.username}</h6>
                      </Col>
                        <Col sm={8}>
                        <h6 className="mb-3 text-center">
                          Performance: {Math.round((user.quizData.totalCorrect / user.quizData.questionsAnswered) * 100)}%
                        </h6>
                        <div
                          style={{
                            position: "relative",
                            height: "30px",
                            background: "#f0f0f0",
                            borderRadius: "15px",
                            overflow: "hidden",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              height: "100%",
                              width: `${(user.quizData.totalCorrect / user.quizData.questionsAnswered) * 100}%`,
                              background: "#1ff31e",
                            }}
                          ></div>
                          <div
                            style={{
                              position: "absolute",
                              height: "100%",
                              width: `${100 - (user.quizData.totalCorrect / user.quizData.questionsAnswered) * 100}%`,
                              background: "#fb3c3c",
                              left: `${(user.quizData.totalCorrect / user.quizData.questionsAnswered) * 100}%`,
                            }}
                          ></div>
                          <div
                            style={{
                              position: "absolute",
                              width: "100%",
                              height: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              padding: "0 10px",
                              color: "#fff",
                              fontWeight: "bold",
                            }}
                          >
                            <span>{user.quizData.totalCorrect} Correct</span>
                            <span>{user.quizData.questionsAnswered - user.quizData.totalCorrect} Incorrect</span>
                          </div>
                        </div>
                      </Col>
                      </Row>
                    </Card>
                  ))}
                </div>
              </>
            ) : (
              <>
                <UserQuizReport
                  quizData={selectedUser.quizData}
                  username={selectedUser.username}
                  handleUserSelect={handleUserSelect}
                />
              </>
            )}
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default HostQuizReport;
