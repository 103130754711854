// import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
// import { AuthContext } from './components/AuthContext/AuthContext';
// import ProtectedRoute from './components/ProtectedRoute/index';
// import { initGA, logPageView } from './Analytics'; // This now works with react-ga4

// import './App.css';
// import './mobile.css';
// import NotFound from "./pages/notFound/NotFound";
// import Main from './pages/main/main';
// import Dashboard from './pages/dashboard/dashboard';
// import Explore from './pages/explore/explore';
// import Signup from './pages/signup/signup';
// import Login from './pages/login/login';
// import PasswordReset from './pages/passwordReset/passwordReset';
// import EditSet from './pages/editSet/editSet';
// import JeopardySetUp from "./pages/jeopardySetUp/jeopardySetUp";
// import GameSelect from "./pages/gameSelect/gameSelect";
// import Buzzer from './pages/buzzer/buzzer';
// import { Nav, NavUser } from './components/Navbar';
// import API from "./utils/API";
// import { useState, useContext, useEffect } from "react";
// import TicTacToe from "./games/tictactoe/tictactoe";
// import TreasureHuntSetUp from "./pages/treasureHuntSetUp/treasureHuntSetUp";
// import MildlyInfuriatedBird from './games/mildlyInfuriatedBird/mildlyInfuriatedBird';
// import BasketballGame from './games/basketball/basketballGame';
// import KpGameSelect from "./pages/kpGameSelect/kpGameSelect";
// import GameHost from './pages/gameHost/gameHost';
// import JoinGame from "./pages/joinGame/joinGame";
// import PreviewJeopardy from "./components/previewJeopardy";
// import HungryHero from './games/HungryHero/HungryHero';
// import HeroOfDawn from './games/HeroOfDawn/HeroOfDawn';

// import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
// import TermsOfService from "./components/TermsOfService/TermsOfService";

// const shouldHideNavbar = (pathname) => {
//   const lowerPathname = pathname.toLowerCase();
//   const staticRoutes = [
//     '/buzzer',
//     '/runner',
//     '/mildlyinfuriatedbird',
//     '/basketball',
//     '/join',
//     '/hungryhero',
//     '/heroofdawn',

//   ];

//   const dynamicRoutesPatterns = [
//     /^\/join(?:\/\d+)?$/, // Matches "/join" and "/join/:id"
//     /^\/Kooblo\/play\/(?:tictactoe|treasurehunt)\/\d+$/, // Matches specific Kooblo routes
//     /^\/Koobloparty\/play\/(?:gameType\/)?\d+$/, // Matches Koobloparty dynamic routes
//   ];

//   return staticRoutes.includes(lowerPathname) || dynamicRoutesPatterns.some(pattern => pattern.test(lowerPathname));
// };

// function App() {
//   const { authenticated } = useContext(AuthContext);
//   const location = useLocation();
  
//   const showNavbar = !shouldHideNavbar(location.pathname);

//   useEffect(() => {
//     initGA(); // Initialize Google Analytics on component mount
//     logPageView(); // Log the initial page view
//   }, []); // Only run once on mount

//   useEffect(() => {
//     logPageView(); // Log the page view on location change
//   }, [location]); // Dependency on location changes

//   return (
//       <div>
//           {showNavbar && (authenticated ? <NavUser /> : <Nav />)}

//           <Routes>



//               <Route exact path="/" element={<Main />} />
//               <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
//               <Route exact path="/terms-of-service" element={<TermsOfService />} />
//               <Route exact path="/explore" element={<Explore />} />
//               <Route exact path="/dashboard/*" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
//               <Route exact path="/MildlyInfuriatedBird" element={<MildlyInfuriatedBird />} />
//               <Route exact path="/HungryHero" element={<HungryHero />} />
//               <Route exact path="/Basketball" element={<BasketballGame />} />
//               <Route exact path="/HeroOfDawn" element={<HeroOfDawn />} />
//               <Route exact path="/signup" element={<Signup />} />
//               <Route exact path="/login" element={<Login />} />
//               <Route path="/reset-password" element={<PasswordReset />} />
//               <Route exact path="/jeopardy/edit/:id" element={<EditSet />} />
//               <Route exact path="/play/jeopardy/:id" element={<JeopardySetUp />} />
//               <Route exact path="/jeopardy/preview/:id" element={<PreviewJeopardy />} />
//               <Route exact path="/Kooblo/play/:id" element={<GameSelect />} />
//               <Route exact path="/Kooblo/play/tictactoe/:id" element={<TicTacToe />} />
//               <Route exact path="/Kooblo/play/treasurehunt/:id" element={<TreasureHuntSetUp />} />
//               <Route exact path="/Koobloparty/play/:id" element={<KpGameSelect />} />
//               <Route exact path="/Koobloparty/play/:gameType/:id" element={<GameSelect />} />
//               <Route exact path="/koobloparty/host/:gameType/:id" element={<GameHost />} />
//               <Route exact path="/join" element={<JoinGame />} />
//               <Route exact path="/join/:id" element={<JoinGame />} />
//               <Route exact path="/buzzer" element={<Buzzer />} />
//               <Route exact path="/buzzer/:id" element={<Buzzer />} />
//               <Route path="*" element={<NotFound/>} />
//           </Routes>
//       </div>
//   );
// }

// export default App;
import React, { Suspense, useContext, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { AuthContext } from './components/AuthContext/AuthContext';
import ProtectedRoute from './components/ProtectedRoute/index';
import { initGA, logPageView } from './Analytics';
import './App.css';
import './mobile.css';
import NotFound from "./pages/notFound/NotFound";
import Main from './pages/main/main';
import Dashboard from './pages/dashboard/dashboard';
import Explore from './pages/explore/explore';
import Signup from './pages/signup/signup';
import Login from './pages/login/login';
import PasswordReset from './pages/passwordReset/passwordReset';
import EditSet from './pages/editSet/editSet';
import GameSelect from "./pages/gameSelect/gameSelect";
import Buzzer from './pages/buzzer/buzzer';
import { Nav, NavUser } from './components/Navbar';
import JoinGame from "./pages/joinGame/joinGame";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService/TermsOfService";
import KpGameSelect from "./pages/kpGameSelect/kpGameSelect";

// Lazy-loaded routes:
const MildlyInfuriatedBird = React.lazy(() => import('./games/mildlyInfuriatedBird/mildlyInfuriatedBird'));
const HungryHero = React.lazy(() => import('./games/HungryHero/HungryHero'));
const BasketballGame = React.lazy(() => import('./games/basketball/basketballGame'));
const HeroOfDawn = React.lazy(() => import('./games/HeroOfDawn/HeroOfDawn'));
const JeopardySetUp = React.lazy(() => import("./pages/jeopardySetUp/jeopardySetUp"));
const TicTacToe = React.lazy(() => import("./games/tictactoe/tictactoe"));
const TreasureHuntSetUp = React.lazy(() => import("./pages/treasureHuntSetUp/treasureHuntSetUp"));
const GameHost = React.lazy(() => import('./pages/gameHost/gameHost'));
function App() {
  const { authenticated } = useContext(AuthContext);
  const location = useLocation();

  const shouldHideNavbar = (pathname) => {
    const lowerPathname = pathname.toLowerCase();
    const staticRoutes = [
      '/buzzer',
      '/runner',
      '/mildlyinfuriatedbird',
      '/basketball',
      '/join',
      '/hungryhero',
      '/heroofdawn',
    ];

    const dynamicRoutesPatterns = [
      /^\/join(?:\/\d+)?$/,
      /^\/Kooblo\/play\/(?:tictactoe|treasurehunt)\/\d+$/,
      /^\/Koobloparty\/play\/(?:gameType\/)?\d+$/,
    ];

    return staticRoutes.includes(lowerPathname) || dynamicRoutesPatterns.some(pattern => pattern.test(lowerPathname));
  };

  const showNavbar = !shouldHideNavbar(location.pathname);

  useEffect(() => {
    initGA();
    logPageView();
  }, []);

  useEffect(() => {
    logPageView();
  }, [location]);

  return (
    <div>
      {showNavbar && (authenticated ? <NavUser /> : <Nav />)}
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
        
          <Route exact path="/" element={<Main />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/terms-of-service" element={<TermsOfService />} />
          <Route exact path="/explore" element={<Explore />} />
          <Route exact path="/dashboard/*" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          <Route exact path="/signup" element={<Signup />} />
          <Route exact path="/login" element={<Login />} />
          <Route path="/reset-password" element={<PasswordReset />} />
          <Route exact path="/jeopardy/edit/:id" element={<EditSet />} />
          
          {/* Lazy loaded routes */}
          <Route exact path="/MildlyInfuriatedBird" element={<MildlyInfuriatedBird />} />
          <Route exact path="/HungryHero" element={<HungryHero />} />
          <Route exact path="/Basketball" element={<BasketballGame />} />
          <Route exact path="/HeroOfDawn" element={<HeroOfDawn />} />
          <Route exact path="/play/jeopardy/:id" element={<JeopardySetUp />} />
          <Route exact path="/Kooblo/play/tictactoe/:id" element={<TicTacToe />} />
          <Route exact path="/Kooblo/play/treasurehunt/:id" element={<TreasureHuntSetUp />} />
          <Route exact path="/koobloparty/host/:gameType/:id" element={<GameHost />} />
          
          {/* Other routes that are not lazy loaded */}
          <Route exact path="/join" element={<JoinGame />} />
          <Route exact path="/join/:id" element={<JoinGame />} />
          <Route exact path="/buzzer" element={<Buzzer />} />
          <Route exact path="/buzzer/:id" element={<Buzzer />} />
          <Route exact path="/Koobloparty/play/:id" element={<KpGameSelect />} />
          <Route exact path="/Koobloparty/play/:gameType/:id" element={<GameSelect />} />
          <Route exact path="/Kooblo/play/:id" element={<GameSelect />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
