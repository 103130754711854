import React from 'react';
import "./style.css";
import { Card, CardText, CardBody, CardTitle, FormGroup,Input, CardImg} from 'reactstrap';
import koobloIMG from '../../assets/images/Kooblo.PNG';
import API from '../../utils/API';
import { useState,useRef,useEffect } from "react";
import ImageUploader from '../ImageUploader';
import axios from "axios";

const GameCard = ({ 
  creator,
  image, 
  title = 'Default Name', 
  description = 'Default About Text', 
  gametype, 
  questionCount, 
  playCount, 
  onPlayClick, 
  onEditClick,
  onUpdateSet,
  onViewClick,
  onDeleteClick,
  id 
}) => {
  const [currentImage, setCurrentImage] = useState(image);
  const [displayedImage,setDisplayedImage] = useState(currentImage || koobloIMG)
  const apiKey = process.env.REACT_APP_UNSPLASH_API_KEY;
  const [currentTitle, setCurrentTitle] = useState(title);
  const [currentDescription, setCurrentDescription] = useState(description);

  const [editSetToggle,setEditSetToggle] = useState(false);
  const [editedTitle, setEditedTitle] = useState(currentTitle);
  const [editedDescription, setEditedDescription] = useState(description);
  const [deleteToggle,setDeleteToggle] = useState(false)
  //image uploader code start here!!!!!!!!!!!!!!`~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~!!!!!!!!!!!!!!`~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~!!!!!!!!!!!!!!`~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  const [previewImage, setPreviewImage] = useState(null);
  const [files, setFiles] = useState([]);
  const [ImgApiModal, setImgApiModal] = useState(false);
  const [apiImages, setApiImages] = useState([]);
  const [URLModal, setURLModal] = useState(false);
  const [imageApiDesc, setimageApiDesc] = useState("");
  const [apiPageCount, setApiPageCount] = useState(1);
  const [imageURL, setimageURL] = useState("");
  const [loading, setLoading] = useState(false);
  const modalBodyRef = useRef(null);
  const [errors,setErrors] = useState({})

  const fileInput = useRef(null);
  const toggleImgApiModal = () => setImgApiModal(!ImgApiModal);
  const toggleURLModal = () => setURLModal(!URLModal);

  const onImageApiDescChange = e =>{
    setimageApiDesc(e.target.value)
    setApiPageCount(1);
}
const selectImageAPI = url =>{
    setImgApiModal(!ImgApiModal)
    setPreviewImage(url)
    setFiles(url)
}
const searchImageApi = () => {
  setLoading(true);
  setApiImages([]); // Reset the images array
  setApiPageCount(1); // Reset to the first page
  axios.get(`https://api.unsplash.com/search/photos`, {
      params: {
          query: imageApiDesc,
          page: apiPageCount,
          per_page: 20,
      },
      headers: {
          Authorization: `Client-ID ${apiKey}`
      }
  }).then(res => {
          // Handle the response
          setApiImages(res.data.results);
          setApiPageCount(2);
      })
      .catch(error => {
          console.error('Error fetching images from Unsplash:', error);
      })
      .finally(() => {
          setLoading(false);
      });

};
useEffect(() => {
  if (ImgApiModal) {
    setLoading(false);
  }
}, [ImgApiModal]);

useEffect(() => {
  const handleScroll = (event) => {
      const ApiImgBody = event.target;
      if (ApiImgBody.scrollTop + ApiImgBody.clientHeight >= ApiImgBody.scrollHeight - 5 && !loading) {
          console.log(apiPageCount)
          setLoading(true);
          fetchImages();
      }
  };

  const ApiImgBody = document.getElementById('ApiImgBody');
  if (ApiImgBody) {
      ApiImgBody.addEventListener('scroll', handleScroll);
      return () => {
          ApiImgBody.removeEventListener('scroll', handleScroll);
      };
  }
}, [ImgApiModal, loading, ]);
const fetchImages = () => {
  axios.get(`https://api.unsplash.com/search/photos`, {
      params: {
          query: imageApiDesc,
          page: apiPageCount,
          per_page: 20,
      },
      headers: {
          Authorization: `Client-ID ${apiKey}`
      }
  }).then(res => {
          // Handle the response
          setApiImages(prevImages => [...prevImages, ...res.data.results]);
          setApiPageCount(prevPageCount => prevPageCount + 1);
      })
      .catch(error => {
          console.error('Error fetching images from Unsplash:', error);
      })
      .finally(() => {
          setLoading(false);
      });
};
// end api image search
const onFileChange = e => {
  if(e.target.files.length !== 0){
      setPreviewImage(URL.createObjectURL(e.target.files[0]))
      setFiles(e.target.files);
  }
}
const onURLChange = e =>{
  setimageURL(e.target.value);
  // console.log(e.target.value)
}
const handleURLSubmit = e => {
  setPreviewImage(imageURL);
  setFiles(imageURL);
  setURLModal(!URLModal);
}


  const removePreviewImage = e =>{
    setPreviewImage("");
    setFiles(null);
  }    
  // //INVALID IMAGE CALLBACK
  const invalidImage = e =>{
      console.log("invalid image")
  }
  //image uploader code end here!!!!!!!!!!!!!!`~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~!!!!!!!!!!!!!!`~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~!!!!!!!!!!!!!!`~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  const gameTypeBorder = (gameType) => {
    switch (gameType) {
      case 'Jeopardy':
        return 'jeopardy-border';
      case 'Kooblo Party':
        return 'kooblo-party-border';
      case 'Kooblo':
        return 'kooblo-border';
      default:
        return 'kooblo-border';
    }
  };

  const gameTypeTag = (gameType) => {
    switch (gameType) {
      case 'Jeopardy':
        return 'jeopardy-tag';
      case 'Kooblo Party':
        return 'kooblo-party-tag';
      case 'Kooblo':
        return 'kooblo-tag';
      default:
        return 'kooblo-tag';
    }
  };
  const onEditSetClick=()=>{
    setEditSetToggle(!editSetToggle);
    setEditedTitle(currentTitle);
    setEditedDescription(currentDescription);
    if(currentImage){
      setFiles(currentImage);
      setPreviewImage(currentImage);
    }else{
      setFiles(null);
      setPreviewImage(null);
    }

  }
  const onSaveClick = async () => {
    // Validation check
    let validationErrors = {};

    // Check title length
    if (editedTitle.length > 24) {
      validationErrors.title = "Title must be 24 characters or less.";
    }
  
    // Check description length
    if (editedDescription.length > 100) {
      validationErrors.description = "Description must be 100 characters or less.";
    }
  
    // If there are validation errors, set them and stop execution
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    } else {
      setErrors({}); // Clear errors if valid
    }
    // Create a FormData object to handle the file upload and other data
    const formData = new FormData();
    formData.append("setId", id); // Append setId
    formData.append("title", editedTitle); // Append title
    formData.append("description", editedDescription); // Append description
  
    // Check if a new image was uploaded or selected from the API
    if (files && files.length > 0 && typeof files !== "string") {
      formData.append("image", files[0]); // Append file if it's uploaded
    } else if (typeof files === "string") {
      formData.append("image", files); // Append image URL if it's a string
    }
  
    try {
      // Call the API to update the set
      const response = await API.updateSet(formData);
  
      if (response.status === 200) {
        // Handle success (refresh data or close the modal, etc.)
        setCurrentDescription(response.data.updatedData.description);
        setCurrentTitle(response.data.updatedData.title);
        setCurrentImage(response.data.updatedData.image);
        if(response.data.updatedData.image){
          setDisplayedImage(response.data.updatedData.image);
          console.log(response.data.updatedData.image)  
        }
        onUpdateSet(response.data.updatedData); // Trigger the update in MyGames
        setEditSetToggle(false); // Close the edit mode
      }
    } catch (error) {
      console.error("Error updating set", error);
    }
  };
    return (
    <div className='nunito'>
      <Card className={`card-container ${gameTypeBorder(gametype)}`} key={id}>
        {deleteToggle?
          // <Row noGutters className="h-100 min-height-200 m-0">
          <div >

              <div className='h-100 align-content-center text-center min-height-200'>
              <i className="fa-solid fa-trash-can"></i>
              <p className="titan-one-regular p-0 m-0" >Are you sure?</p>
              </div>
              <div className="class-link-group nunito">
                  <p className="class-card-custom-link class-card-custom-link-delete" onClick={onDeleteClick}>Yes</p>
                  <p className="class-card-custom-link " onClick={()=>setDeleteToggle(false)}>No</p>
              </div>
          </div>
        :
              (editSetToggle ?
                <>
                    {     previewImage?
                        <div>
                        <img src={previewImage} className="preview-image m-0 p-0" onError={()=>invalidImage()}></img>
                        <button onClick={removePreviewImage} className="remove-preview-image">Remove</button>
                        </div>
            
                        :      <ImageUploader
                                setPreviewImage={setPreviewImage}
                                setFiles={setFiles}
                                toggleImgApiModal={toggleImgApiModal}
                                ImgApiModal={ImgApiModal}
                                onImageApiDescChange={onImageApiDescChange}
                                apiImages={apiImages}
                                setApiImages={setApiImages}
                                selectImageAPI={selectImageAPI}
                                searchImageApi={searchImageApi}
                                fileInput={fileInput}
                                onFileChange={onFileChange}
                                URLModal={URLModal}
                                toggleURLModal={toggleURLModal}
                                onURLChange={onURLChange}
                                handleURLSubmit={handleURLSubmit}
                                apiPageCount={apiPageCount}
                                setApiPageCount={setApiPageCount}
                                loading={loading}
                                modalBodyRef={modalBodyRef}
                            />
                      }
                  
                  <CardBody className='p-2'>
                    {/* Editable Input for Name */}
                    <FormGroup>
                    {errors.title && <small className="text-danger">{errors.title}</small>} {/* Error message */}
                      <Input
                        type="text"
                        value={editedTitle}
                        onChange={(e) => setEditedTitle(e.target.value)}
                        placeholder="Title"
                        className="card-title"
                      />

                    </FormGroup>
                    {/* Editable Input for About */}
                    <FormGroup>
                    {errors.description && <small className="text-danger">{errors.description}</small>} {/* Error message */}
                      <Input
                        type="textarea"
                        value={editedDescription}
                        onChange={(e) => setEditedDescription(e.target.value)}
                        placeholder="Description"
                        className="card-text"
                      />
                    </FormGroup>

                  </CardBody>
                  <div className="card-info-container d-flex justify-content-between align-items-center p-2">
                  </div>
                  <div className="class-link-group nunito">
                        <p className="class-card-custom-link save-button" onClick={onSaveClick}>Save</p>
                        <p className="class-card-custom-link" onClick={onEditSetClick}>Cancel</p>
                        <p className="class-card-custom-link delete-button" onClick={()=>setDeleteToggle(true)}>Delete</p>
                  </div>
                </>
                
              
                :
                <>
                <div className="gamecard-image-body">
                  <img className="gamecard-image w-100" src={displayedImage} alt="Game" onError={()=>setDisplayedImage(koobloIMG)}/>
                </div>
                <p className={`titan-one-small ${gameTypeTag(gametype)}`}> {gametype} </p>
                <CardBody className='p-2'>
                <div className="d-flex justify-content-between align-items-center">
                <CardTitle className="card-title text-wrap" style={{ wordBreak: "break-word", overflowWrap: "break-word" }}>
                  {currentTitle}
                </CardTitle>
                  
                  {onEditClick&& 
                  <small>
                    <i 
                    className="fa-solid fa-pen-to-square edit-set-icon" 
                    onClick={onEditSetClick} 
                  />
                    </small>}
                </div>
                <CardText>{currentDescription}</CardText>
                </CardBody>
                <div className="card-info-container d-flex justify-content-between align-items-center p-2">
                  <div className="game-info d-flex align-items-center">
                    {gametype !== "Jeopardy" && (
                      <div>
                        <i className="fa-regular fa-circle-question"></i> 
                        <small className="ms-2 p-1"> {questionCount} </small>
                      </div>
                    )}
                    <div className='p-2'>
                      <i className="fa-regular fa-circle-play ms-4"></i>  
                      <small className="ms-2 p-1">{playCount}</small>
                    </div>
                  </div>
                  <div className="author-info d-flex align-items-center">
                    {creator &&<>
                      <i className="fa-solid fa-feather"></i>  
                      <small className="ms-2"> {creator}</small></>}
                  </div>
                </div>
                <div className="class-link-group nunito">
                  <p className="class-card-custom-link" onClick={onPlayClick}><i className="fa-solid fa-gamepad"></i></p>
                  {onEditClick && <p className="class-card-custom-link" onClick={onEditClick}><i className="fa-solid fa-pen-to-square"></i></p>}
                  {onViewClick && <p className="class-card-custom-link" onClick={onViewClick}><i className="fa-solid fa-magnifying-glass"></i></p>}
                </div>
                </>
            )
          }
      </Card>
    </div>
  );
};

export default GameCard;
